<!-- BaseFromImage.vue -->
<template>
  <div>
    <img
      width="240"
      height="320"
      v-if="this.atractionsMetadataListFirestItem && this.atractionsMetadataListFirestItem.url"
      :src="this.atractionsMetadataListFirestItem.url"
      class="attraction-main-image"
    >
    <div v-if="this.atractionsMetadataListFirestItem && this.atractionsMetadataListFirestItem.dateTaken">
      <H5>
        {{ dateTitle }}
      </H5>
      <H3>{{ this.atractionsMetadataListFirestItem.dateTaken }}</H3>
    </div>
    <div v-if="isAddingAccommodationsFromImages">
      <div class="trip-contact-field">
        <div class="trip-input-field">
          <p style="font-size: 1.25rem; margin-bottom: 10px;">אנא בחר את תאריכי הצ'ק-אין והצ'ק-אאוט של המקום לינה</p>
            <flat-pickr
              v-model="updatedDate"
              class="border-0 bg-white form-control datepicker text-right"
              placeholder="צ'ק אין   ←   צ'ק אאוט"
              :config="{ mode: 'range', dateFormat:'Y:m:d' }"
            ></flat-pickr>
            <img
            class="coulunder_image"
            :src="require('@/assets/images/icons/icons8-edit-calendar-50.png')"
            >
        </div>
      </div>
    </div>

    <div v-if="this.atractionsMetadataListFirestItem && !this.atractionsMetadataListFirestItem.dateTaken">
      <button 
        type="button"
        class="btn btn-warning"
        @click="InternallAddToRide({})" 
      >
        we don't have information about this image and we will not add it at this time - click me to continue
      </button>
    </div>
    
    <div v-if="!this.shouldPresentMoreOptionsNamesForAttraction">
      <div v-if="this.openAichosenPlace && this.openAichosenPlace.success === 'true'">
        <h1>is it :</h1>
        <h1>{{ this.openAichosenPlace.displayName.text }}?</h1>
      </div>
      <h1 v-else-if="this.openAichosenPlace && this.openAichosenPlace.success === 'false' && this.openAichosenPlace.displayName.text">
        {{ this.openAichosenPlace.displayName.text }}
      </h1>
      <div v-else-if="this.openAichosenPlace && this.openAichosenPlace.success === 'false'">
        <h1>We couldn't identify the place name.</h1>
        <button type="button" class="btn btn-warning" @click="presentMoreOptionsNamesForAttraction()">
          show me other options
        </button>
      </div>
      <button type="button" class="btn btn-primary large-btn" 
          @click="InternallAddToRide({type: config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GOOGLE_PLACE_ID, data: { placeId: openAichosenPlace.id } })">
        yes
      </button>
      <button type="button" class="btn btn-warning large-btn" @click="presentMoreOptionsNamesForAttraction()">
        no
      </button>
    </div>

    <h1>
      לחצו על הנקודה במפה שקשורה לתמונה שצולמה
    </h1>
    <GlobalMap
      class="single_ride_map11"
      passed_class="single_ride_map"
      @userPressedOnMap="handleMapPress"
      >
    </GlobalMap>

    <div  v-if="this.shouldPresentMoreOptionsNamesForAttraction"> <!-- this si else from the above of the globalMap -->
      <h1>We couldn't identify the place name.</h1>
      <h2>Please select a name from the list or enter it manually.</h2>
      <gmap-autocomplete
        @place_changed="editPlaceAndAddToRide"
        :select-first-on-enter="true"
        placeholder="הכניסו את שם המקום"
        v-on:input="handleInputAddGooglePlaceForImage"
        class="form-control pading_top_and_butom_3 text-end py-3 fs-5 rounded-pill border-0 bg-white shadow"
        :options="{ fields: ['place_id'] }"
      />
      <div v-for="(meta, index) in googleLocationsOptions" :key="index">
        <button type="button" class="btn btn-warning button-spacing" 
          @click="InternallAddToRide({type: config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GOOGLE_PLACE_ID, data: { placeId: meta.id } })">
          {{ meta.displayName.text }}
        </button>
        <p/>
      </div>
      <div v-if="this.atractionsMetadataListFirestItem">
        <button type="button" class="btn custom-skip-btn" @click="InternallAddToRide({type: config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GEOLOCATION, data: { location: atractionsMetadataListFirestItem.location } })">
          Add this image and enter the name later.
        </button>
      </div>
      <div v-if="this.atractionsMetadataListFirestItem">
        <button type="button" class="btn custom-skip-btn" @click="InternallAddToRide({type: config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GEOLOCATION, data: { location: imageUploadTripDefaultPresentedLocation } })">
          Add the image using the most recent geolocation.
        </button>
      </div>
      <button type="button" class="btn custom-skip-btn" @click="InternallAddToRide({})">
        Skip this image and continue.
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { UPLOAD_RIDE_STATES } from '@/constants/uploadRideStates.js'; // Assuming you have this constant imported
import config from '@/common/config';
import flatPickr from "vue-flatpickr-component";
import GlobalMap from './GlobalMap';
import "flatpickr/dist/flatpickr.css";


export default {
  components: {
    flatPickr,
    GlobalMap,

  },
  props: {
    dateTitle: {
      type: String,
      required: true
    },
    addToRide: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      shouldPresentMoreOptionsNamesForAttraction: false,
      config: config.config,
    };
  },
  computed: {
    ...mapGetters([
      'atractionsMetadataList',
      'atractionsMetadataListFirestItem',
      'imageUploadTripDefaultPresentedLocation',
      'openAichosenPlace',
      'googleLocationsOptions',
      'uploadRideIdFromImage',
      'isFirstTimeAddingImages',
      'uploadRideState',
    ]),
    updatedDate:{
      get () {
        if( this.atractionsMetadataListFirestItem && this.atractionsMetadataListFirestItem.dateTaken ){
          return this.atractionsMetadataListFirestItem.dateTaken.split(' ')[0] + ' to ' + this.atractionsMetadataListFirestItem.dateTaken.split(' ')[0];
        }
        else {
          return '';
        }
      },
      set (value) {
        this.$store.commit('setLocationFromImageCheckIn', value.split(' to ')[0])
        this.$store.commit('setLocationFromImageCheckOut', value.split(' to ')[1])
      },
    },
    isAddingAccommodationsFromImages() {
      return this.uploadRideState === UPLOAD_RIDE_STATES.ADDING_ACCOMMODATIONS_FROM_IMAGES;
    },

  },
  watch: {
    openAichosenPlace(newVal) {
      if (newVal && newVal.success === 'false') {
        this.presentMoreOptionsNamesForAttraction();
      }
      else if (newVal && newVal.success === 'true') {
        this.DontPresentMoreOptionsNamesForAttraction();
      }
      if ( this.atractionsMetadataListFirestItem && this.atractionsMetadataListFirestItem.location && this.firestPlaceLat() ){
        setTimeout(() => {
        this.setOneMarker( {  latPoint: this.firestPlaceLat(),
                              lngPoint: this.firestPlaceLng(),
                              name:"the new point",
                              zoomLevel: 16,} )
        }, 100);
      }
      else{
        setTimeout(() => {
        // this is work arownd to set it at the begining of the creation  
        this.setOneMarker({
          latPoint: this.dmsToDecimal(this.imageUploadTripDefaultPresentedLocation.latitude, this.imageUploadTripDefaultPresentedLocation.latitudeRef),
          lngPoint: this.dmsToDecimal(this.imageUploadTripDefaultPresentedLocation.longitude, this.imageUploadTripDefaultPresentedLocation.longitudeRef),
          name: "the new point",
          zoomLevel: 14,
        });
      }, 100);
      }

    },
  },
  methods: {
    ...mapActions([
      'sendingImageMetaData',
      'showModal',
      'openRideInformationPage',
      'showModalWithParameters',
      'setOneMarker',
      'fetchRide',
      'shiftAndReturnAtraction',
    ]),

    handleMapPress(parameters) {
      if( parameters.placeId){
        this.showModalWithParameters({  inerModalParameters : 
                                          { callBack: this.InternallAddToRide,
                                            placeId: parameters.placeId 
                                          },
                                        type: 'doYouWantToAddThisPointToRide'
                                      });
      }

    },
    handleInputAddGooglePlaceForImage(event) {
      console.log('this place information is: ', event.target.value);
    },
    editPlaceAndAddToRide(place) {
      // at this part we are adding the information from the autocomplite box
      const placeStructure = {type: config.config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GOOGLE_PLACE_ID, data: {placeId: place.place_id}}     
      this.InternallAddToRide(placeStructure);
    },
    presentMoreOptionsNamesForAttraction() {
      this.shouldPresentMoreOptionsNamesForAttraction = true;
    },
    DontPresentMoreOptionsNamesForAttraction() {
      this.shouldPresentMoreOptionsNamesForAttraction = false;
    },
    async InternallAddToRide(place) {
      // const placeStructure = {type: "place_id", data: {placeId: place.place_id}}     
      this.DontPresentMoreOptionsNamesForAttraction();
      const isLastItem = this.atractionsMetadataList.length === 1;
      if (Object.keys(place).length > 0) {
        if (isLastItem) {
          await this.addToRide(place);
        } else {
          this.addToRide(place);
        }
      } else {
        const removedElement = await this.shiftAndReturnAtraction();
        console.log('Place is empty, skipping ', removedElement);
      }

      if (this.atractionsMetadataList.length > 0) {
        this.sendingImageMetaData();
      } else {
        if (this.isFirstTimeAddingImages && this.uploadRideState === UPLOAD_RIDE_STATES.ADDING_ATRACTIONS_FROM_IMAGES) {
          this.openRideInformationPage(this.uploadRideIdFromImage);
          this.showModalWithParameters({
            errorNumber: 'upload_trip_adding_text_stage',
            type: 'textConverter',
          });
        }
        else if (this.isFirstTimeAddingImages && this.uploadRideState === UPLOAD_RIDE_STATES.ADDING_ACCOMMODATIONS_FROM_IMAGES) {
          this.$router.push('/single_ride_with_map/' + this.uploadRideIdFromImage);
          this.showModalWithParameters({
            errorNumber: 'plese_add_the_activity_info',
            type: 'textConverter',
          });
        }
        else {
          this.$router.push('/single_ride_with_map/' + this.uploadRideIdFromImage);
        }
      }
    },
    
    firestPlaceLat() {
      if(this.atractionsMetadataListFirestItem){
        return this.dmsToDecimal(this.atractionsMetadataListFirestItem.location.latitude, this.atractionsMetadataListFirestItem.location.latitudeRef)
      } 
    },
    
    firestPlaceLng() {
      if(this.atractionsMetadataListFirestItem){
        return this.dmsToDecimal(this.atractionsMetadataListFirestItem.location.longitude, this.atractionsMetadataListFirestItem.location.longitudeRef)
      } 
    },
    
    dmsToDecimal(dms, direction) {
      let decimalDegrees = 0;
      if( dms ){
        const [degrees, minutes, seconds] = dms;
        decimalDegrees = degrees + (minutes / 60) + (seconds / 3600);
      
        // Convert to negative if direction is South or West
        if (['S', 'W'].includes(direction)) {
          decimalDegrees = -decimalDegrees;
        }
      }
      else{
        console.log("failed to pars geolocation ", dms)
      }
      return decimalDegrees;
    }
  },
};
</script>

<style scoped>
.large-btn {
  font-size: 2rem;
  padding: 1.5rem 3rem;
  margin: 0 10px;
}

.custom-skip-btn {
  background-color: #ff5733; /* Custom orange color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-skip-btn:hover {
  background-color: #e74c3c; /* Darker color on hover */
}

.button-spacing {
  margin-bottom: 5px; /* Adjust the spacing as needed */
}
</style>