<template>
  <div>
    <div class="accommodation-instructions">
      <p class="larger-text">
        בחלק זה, יש להעלות תמונות של <strong>כל מקומות הלינה</strong> שבהם שהיתם, יחד עם תאריכי הצ'ק-אין והצ'ק-אאוט עבור כל אחד מהם.
        אם אינכם מעוניינים לספק פרטי לינה מדויקים, ניתן להכניס רק את שם העיר.
      </p>
    </div>
    <h1>-----------------------</h1>
    <div class="accommodation-instructions">
      <p class="larger-text">
        In this part, please upload images for <strong>all the accommodations</strong> where you stayed, along with the check-in and check-out dates for each.
        If you prefer not to provide the exact accommodation details, you can just enter the city name.
      </p>
    </div>
    <h1>-----------------------</h1>
    <input type="file" multiple @change="onFileChange">
    <div v-for="(meta, index) in this.atractionsMetadataList" :key="index">
      <h3>Metadata for {{ meta.filename }}</h3>
      <p><strong>Size:</strong> {{ meta.size }} bytes</p>
      <p><strong>Type:</strong> {{ meta.type }}</p>
      <p><strong>Date Taken:</strong> {{ meta.dateTaken }}</p>
      <p><strong>Location:</strong> Latitude: {{ meta.location.latitude }}, Longitude: {{ meta.location.longitude }}</p>
      <p><strong>Uploaded At:</strong> {{ meta.uploadedAt }}</p>
      <p><strong>Download URL:</strong> {{ meta.url }}</p>
    </div>
  </div>
</template>

<script>
import { fileHandlingMixin } from "@/mixins/fileHandlingMixin";
import { mapActions, mapGetters } from "vuex";
import { UPLOAD_RIDE_STATES } from '@/constants/uploadRideStates.js';


export default {
  mixins: [fileHandlingMixin], // Include the mixin
  computed: {
    ...mapGetters(['atractionsMetadataList', 'isAppLoggedIn']),
  },
  methods: {
    ...mapActions([ "addAccommodationsfromImages",
                    "showModal",
                    "showModalWithParameters"]),

    onFileChange(event) {
      if (event.target.files.length > 0) {
        this.files = event.target.files;

        // Pass the addAccommodationsfromImages function as the callback
        this.uploadFiles(() => {
          this.addAccommodationsfromImages();
        }, 'accommodation');
      } else {
        console.log("No files selected");
      }
    },
  },

  created() {
    // we will change it after the user will want to add ride from the trip
    const rideIdFromUrl = this.$route.params.ride_id;
    if (rideIdFromUrl) {
      this.$store.commit("setIsFirstTimeAddingImages", false);
      this.$store.commit("setUploadRideIdFromImage", rideIdFromUrl);
    } else {
      this.$store.commit("setIsFirstTimeAddingImages", true);
      // this.$store.commit("setUploadRideIdFromImage", null);
    }
    this.$store.commit("setUploadRideState", UPLOAD_RIDE_STATES.UPLOADING_ACCOMMODATIONS_IMAGE );

    if (!this.isAppLoggedIn) {
      this.showModal("pleaseLogInInOrderToEditRide");
    }
  },
  beforeDestroy() {
    // at the end of the firest time that the user adding this trip we should remove this condition
    this.$store.commit("setIsFirstTimeAddingImages", false);
  },
};
</script>

<style scoped>
.larger-text {
  font-size: 1.5rem; /* Adjust font size as needed */
  line-height: 1.6;  /* Improve readability with line spacing */
}
</style>