<template>
  <div>
    <div class="game-introduction">
      <h2 style="font-size: 2rem;">איך זה עובד:</h2>
      <p style="font-size: 1.25rem;">כדי להוסיף את המסלול שלך, חילקנו את התהליך לשני חלקים:</p>

      <h3 style="font-size: 1.75rem;">הוספת אטרקציות:</h3>
      <p style="font-size: 1.25rem;">
        העלה את כל התמונות של האטרקציות שבהן ביקרת. העלה תמונה אחת לכל אטרקציה שביקרת בה. התמונה צריכה להיות מצולמת מהטלפון הזה ולייצג את החוויה שלך במקום,מומלץ לאפשר שיתוף מיקום של התמונות.
      </p>

      <h3 style="font-size: 1.75rem;">הוספת מקומות לינה:</h3>
      <p style="font-size: 1.25rem;">
        העלה את כל התמונות של מקומות הלינה. העלה תמונה אחת של המקום בו שהית וספק את תאריכי הצ'ק-אין והצ'ק-אאוט.
      </p>

      <p style="font-size: 1.25rem;">
        בעזרת המידע הזה, ניצור אוטומטית את המסלול שעשית.
      </p>
    </div>

    <h1> -------------------------------</h1>
    
    <div class="game-introduction">
      <h2 style="font-size: 2rem;">How It Works:</h2>
      <p style="font-size: 1.25rem;">To add your ride, we’ve split the process into two parts:</p>

      <h3 style="font-size: 1.75rem;">Add Attractions:</h3>
      <p style="font-size: 1.25rem;">
        Upload all the attractions images. Upload one image per attraction you visited. The photo should be taken with this phone and represent your experience at the location.
      </p>

      <h3 style="font-size: 1.75rem;">Add Accommodations:</h3>
      <p style="font-size: 1.25rem;">
        Upload all the accommodations images. Upload a photo of the place where you stayed and provide the check-in/out dates.
      </p>

      <p style="font-size: 1.25rem;">
        With this information, we'll automatically create the route you traveled.
      </p>
    </div>


<h3 style="font-size: 1.75rem;">הוספת אטרקציות:</h3>

<div class="file-upload-container">
  <input type="file" id="file-input" multiple @change="onFileChange" class="hidden-input">
  <label for="file-input" class="file-label">
    <span>Select your attractions image</span>
  </label>
</div>


    <div v-for="(meta, index) in this.atractionsMetadataList" :key="index">
      <h3>Metadata for {{ meta.filename }}</h3>
      <p><strong>Size:</strong> {{ meta.size }} bytes</p>
      <p><strong>Type:</strong> {{ meta.type }}</p>
      <p><strong>Date Taken:</strong> {{ meta.dateTaken }}</p>
      <p><strong>Location:</strong> Latitude: {{ meta.location.latitude }}, Longitude: {{ meta.location.longitude }}</p>
      <p><strong>Uploaded At:</strong> {{ meta.uploadedAt }}</p>
      <p><strong>Download URL:</strong> {{ meta.url }}</p>
    </div>
  </div>
</template>

<script>
import { fileHandlingMixin } from "@/mixins/fileHandlingMixin";
import { mapActions, mapGetters } from "vuex";
import { UPLOAD_RIDE_STATES } from '@/constants/uploadRideStates.js'
import config from '@/common/config';

export default {
  mixins: [fileHandlingMixin],
  computed: {
    ...mapGetters(['atractionsMetadataList', 'isAppLoggedIn']),
  },
  methods: {
    ...mapActions([ "createOrUpdateTripFromImages",
                    "showModal",
                    "showModalWithParameters"]),

    onFileChange(event) {
      if (event.target.files.length > 0) {
        this.files = event.target.files;

        // Pass the createOrUpdateTripFromImages function as the callback
        this.uploadFiles(() => {
          this.createOrUpdateTripFromImages();
        }, config.config.ATTRACTION_ROOT_PATH);
      } else {
        console.log("No files selected");
      }
    },
  },
  created() {
    const rideIdFromUrl = this.$route.params.ride_id;
    if (rideIdFromUrl) {
      this.$store.commit("setIsFirstTimeAddingImages", false);
      this.$store.commit("setUploadRideIdFromImage", rideIdFromUrl);
    } else {
      this.$store.commit("setIsFirstTimeAddingImages", true);
      this.$store.commit("setUploadRideIdFromImage", null);
    }
    this.$store.commit("setUploadRideState", UPLOAD_RIDE_STATES.UPLOADING_ATRACTIONS_IMAGE );

    if (!this.isAppLoggedIn) {
      this.showModal("pleaseLogInInOrderToEditRide");
    }
  },
};
</script>

<style scoped>
.file-upload-container {
  display: inline-block;
  position: relative;
}

.hidden-input {
  position: absolute;
  left: -9999px;
}

.file-label {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: #007bff; /* A friendly blue button */
  color: white;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.file-label:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.file-label span {
  display: inline-block;
  vertical-align: middle;
}
</style>