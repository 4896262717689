<template>
  <div>
    <div 
      v-for="(dailyActivites, index) in allRide.attractionsByDay"
      :key="index"
      class="day-at-trip"
      :id="'daily_attraction_day_' + index"
      >
      <div class="row mb-3">
        <div class="ride-day-header d-flex">
          <div class="ride-day-date">
            <p class="day-number mb-0 fw-bold fs-4 text-end">
               יום {{ index + 1 }}
            </p>
            <div class="ride-date-and-location d-flex gap-md-4 text-end">
              <p class="mb-0 opacity-75 fs-7">
                {{ dailyActivites.dateTime.split('00:00')[0] }}
              </p>
              <p 
                v-if="activAccommodation( dailyActivites ).cuntry_and_city"
                class="mb-0 d-flex align-items-center">
                {{activAccommodation( dailyActivites ).cuntry_and_city}}
              </p>
            </div>
          </div>
          <a 
            v-if="!compareToUserId( allRide.ride.authorId )"
            @click="serchAttractionToRide(index)"
            class="main-ride-more-attraction btn btn-primary rounded-pill d-flex"
            >
            <p class="my-auto">
              לעוד פעילויות
            </p>
          </a>
        </div>
      </div>
      <div
        class="trip-btn-wraper"
        v-if="compareToUserId( allRide.ride.authorId )">
        <a class="trip-default-btn" @click="chouseTheRightWayToAddAtraction(index)">הוספת פעילות <span>+</span></a>
        <a class="trip-default-btn"
            @click="goToBaseRideAccommodation( dailyActivites.base_trip_accommodation )"
            v-if="!booked( dailyActivites )">הוספת לינה <span>+</span></a>
        <!-- <a class="trip-default-btn" @click="goToRideAccommodation( dailyActivites.accommodation )">הוספת לינה +</a> -->
        <a class="trip-default-btn " @click="addNewDayToRideInternal(index)">הוספת יום <span>+</span></a>
        <a class="trip-default-btn trip-outline" @click="removeDayFromRideInternal(index)">מחיקת יום
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.0804 13.6694L8.3147 7.82202M14.0804 7.82202L8.3147 13.6694" stroke="#DD6E42" stroke-width="2" stroke-linecap="round"/>
          <path d="M11.1975 20.4914C16.5047 20.4914 20.807 16.1281 20.807 10.7457C20.807 5.3633 16.5047 1 11.1975 1C5.89024 1 1.58789 5.3633 1.58789 10.7457C1.58789 16.1281 5.89024 20.4914 11.1975 20.4914Z" stroke="#DD6E42" stroke-width="2"/>
          </svg>
        </a>
      </div>

    <div 
      v-if="fullscreenImageSrc"
      class="fullscreen-overlay"
      @click="closeImageFullscreen">
      <button 
        class="close-button"
        @click="closeImageFullscreen"
      >
        X
      </button>
      <img
        :src="fullscreenImageSrc"
        alt="Fullscreen image"
      />
    </div>
     <!-- start of attraction part -->

      <div
      class="transparent-background-primary">
        <div 
          v-for="(attraction, idx) in dailyActivites.daily_attractions"
          :key="idx"
          >
          <div class="activity card border-0 rounded-4 shadow-sm mb-3">
              <div class="card-body">
                  <div class="position-absolute d-flex align-items-top font-64">
                    {{idx+1}}
                  </div>
                  <div class="internal-row">
                      <div class="attraction-image-contenir internal-col-4 d-flex">
                        <img
                          width="240"
                          height="320"
                          v-if="attraction.image"
                          :originalimage="attraction.image"
                          :src="getResizeImageForMobileDevicesCommon(attraction.image)"
                          class="attraction-main-image"
                          @click="toggleImageFullscreen(attraction.image)"
                          @error="loadErrorImage($event, attraction.image)"
                          :alt="attraction.title"
                          >
                          <p v-if="attraction.imageHtmlAttributions" v-html="getImageHtmlAttributions( attraction.imageHtmlAttributions[0])"></p>
                      </div>
                      <div class="internal-col-8">
                          <div class="">
                              <p class="activity-name fs-4 text-end mb-0">
                                  {{attraction.title}}
                              </p>
                              <CollapseText 
                                class="attraction_information"
                                :text="attraction.text"
                                :uniqueId="Math.random()"
                              />
                              <div 
                                v-if="attraction.rate"
                                class="side_star-location"
                              >
                                <img
                                  width="1024px"
                                  height="1024px"
                                  src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fgoogle_G_icon_transparent.png?alt=media&token=f80515fc-802b-413e-ade2-e16b125e3c05"
                                  alt="Image"
                                >
                                <h5>
                                  {{attraction.rate}}
                                </h5>
                                <img
                                  width="15px"
                                  height="12px"
                                  src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fstar_icon.png?alt=media&token=f2eb5419-e0fd-4c62-b6c2-9499544a5c03"
                                  alt="Star"
                                >
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="d-flex flex-wrap align-items-center gap-2 mt-3"
                       style="justify-content: flex-end !important;">
                      <div
                        @click="showModalAttraction( { 'attractionId': attraction.id, 'sorce':'internal' } )"
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6">
                          פרטים נוספים
                      </div>
                      <div 
                        @click="openWazeLink( attraction )"
                        class="clikable d-inline-flex align-items-center attraction-button-size waze rounded-pill fs-6">
                          <img  alt=""
                                class="ms-2 attraction-button-image"
                                width="20px"
                                height="18px"
                                :src="wazeAtttractionIcon()"
                          >
                          Waze
                      </div>
                      <div 
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
                        @click="shareThisAttraction( allRide.ride.id , attraction.id )"
                      >
                          <img  
                            width="18px"
                            height="18px"
                            alt="shareAtttractionIcon"
                            class="ms-2 attraction-button-image"
                            :src="shareAtttractionIcon()"
                          >
                          שיתוף
                      </div>
                      <div 
                        v-if="attraction.link"
                        @click="goTo(addhttp( attraction.link ))"
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6">
                          <img  
                            width="23px"
                            height="18px"
                            alt="linkAtttractionIcon"
                            class="ms-2 attraction-button-image"
                            :src="linkAtttractionIcon()"
                          >
                          לאתר 
                      </div>
                      <div
                        v-if="haveGetYourGuideLink(attraction)"
                        @click="openGetYourGuideLink( attraction )"
                        class="clikable d-inline-flex align-items-center attraction-button-size get-your-guide rounded-pill fs-6">
                          <img  
                            width="23px"
                            height="18px"
                            alt="GetYourGuideIcon"
                            class="ms-2 attraction-button-image"
                            :src="GetYourGuideIconLink()"
                          >
                          Get Your Guide 
                      </div>

                  </div>

                  <div class="d-flex flex-wrap align-items-center editin-part gap-2"
                       style="justify-content: flex-end !important;"
                       v-if="compareToUserId( allRide.ride.authorId )"
                       >

                      <div
                        @click="removeAttractionFromRideInternal(attraction.id)" 
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
                      >
                          <img  
                            width="32px"
                            height="32px"
                            alt="xImage"
                            class="ms-2 attraction-button-image delete-button"
                            src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FremoveActivity.svg?alt=media&token=29e2e265-3e3a-409c-8066-0f653dfc3399"
                          >
                          הסרה
                      </div>
                      <div 
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
                        v-if="compareToUserId(attraction.authorId)"
                        @click="editAttraction(attraction.id)"
                      >
                          <img  alt="EditIcon"
                                class="ms-2 attraction-button-image edit-button"
                                width="32px"
                                height="32px"
                                src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FeditActivity.svg?alt=media&token=85ea342f-5c95-4a4d-ba3b-fece59e2732c"
                          >
                          עריכה
                      </div>
                      <div 
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
                        @click="openUpdateAttractionTime(attraction)"
                      >
                          <img  
                            class="ms-2 attraction-button-image edit-button"
                            width="32px"
                            height="32px"
                            alt="editImage"
                            src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FeditActivity.svg?alt=media&token=85ea342f-5c95-4a4d-ba3b-fece59e2732c"
                          >
                          החלפת יום
                      </div>
                  </div>
              </div>
          </div>
        </div>
      
      
      <div
        class=""
        :id="(activAccommodation( dailyActivites ).id + '_acommodation_ref_id')"
        v-if="( Object.keys( activAccommodation( dailyActivites ) ).length )"
        >
          <div class="activity card border-0 rounded-4 shadow-sm mb-3">
              <div class="card-body">
                  <div class="position-absolute d-flex align-items-top font-64">
                    <i
                      class="fa-solid fa-building-columns">
                        <img
                          alt="accommodationIcon"
                          width="64px"
                          height="64px"
                          src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fdesign-04-24%2Faccommodation_icon_30.png?alt=media&token=684ee9d9-0934-4581-8be2-6bcadf876faa"
                          >
                    </i>
                  </div>
                  <div class="internal-row">
                      <div class="attraction-image-contenir internal-col-4 d-flex">
                        <img
                          v-if="activAccommodation( dailyActivites ).image"
                          :originalimage="activAccommodation( dailyActivites ).image"
                          :src="activAccommodation( dailyActivites ).image"
                          class="attraction-main-image"
                          @click="toggleImageFullscreen(activAccommodation( dailyActivites ).image)"
                          @error="loadErrorImage($event, activAccommodation( dailyActivites ).image)"
                          :alt="activAccommodation( dailyActivites ).name"
                          width="240"
                          height="320"

                          >
                      </div>
                      <div class="internal-col-8">
                          <div class="">
                              <p 
                                class="activity-name fs-4 text-end mb-0"
                                v-if="activAccommodation( dailyActivites ).cuntry_and_city"
                                >
                                לינה: {{activAccommodation( dailyActivites ).cuntry_and_city}}                              
                              </p>
                              <p 
                                class="activity-name fs-4 text-end mb-0"
                              >
                               {{activAccommodation( dailyActivites ).name}}
                              </p>
                              <CollapseText 
                                class="attraction_information"
                                :text="activAccommodation( dailyActivites ).text"
                                :uniqueId="Math.random()"
                              />
                              <div 
                                v-if="activAccommodation( dailyActivites ).rate"
                                class="side_star-location"
                              >
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fgoogle_G_icon_transparent.png?alt=media&token=f80515fc-802b-413e-ade2-e16b125e3c05"
                                alt="Image"
                                width="1024px"
                                height="1024px"
                                >
                                <h5>
                                  {{activAccommodation( dailyActivites ).rate}}
                                </h5>
                                <img
                                src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fstar_icon.png?alt=media&token=f2eb5419-e0fd-4c62-b6c2-9499544a5c03"
                                alt="Star"
                                width="15px"
                                height="12px"
                                >
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- date section -->
                  <div class="d-flex my-3">
                    <div class="centering_with_gap flex all_border_blue_for_accommodation_dates">
                      <div class="field">
                        <img 
                         src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fride_summary%2Fcalendar_icon.png?alt=media&token=3be4a7c8-1f53-4778-9f11-2c60dd1defaf"
                         alt="calendar"
                         width="21px"
                         height="24px"
                        >
                      </div>            
                      <div class="field">
                        <i class="calendar icon"></i>
                        <span class="main_ride_dates">{{activAccommodation( dailyActivites ).check_out_date}}</span>
                      </div>
                      <img
                       width="18px"
                       height="20px"
                       src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/icons%2Fride_summary%2Farrow-right-solid.png?alt=media&token=0dbdd199-477a-41a7-a5b2-752a38b4235e"
                       alt="arrow"
                      >
                      <div class="field">
                        <i class="calendar icon"></i>
                        <span class="main_ride_dates">{{activAccommodation( dailyActivites ).check_in_date}}</span>
                      </div>
                    </div>
                  </div>

                  <!-- icons section -->
                  <div class="d-flex flex-wrap align-items-center gap-2 mt-3"
                       style="justify-content: flex-end !important;">
                      <div 
                        @click="openWazeLink( activAccommodation( dailyActivites ) )"
                        class="clikable d-inline-flex align-items-center attraction-button-size waze rounded-pill fs-6">
                          <img  alt="wazeAtttractionIcon"
                                width="20px"
                                height="18px"
                                class="ms-2 attraction-button-image"
                                :src="wazeAtttractionIcon()"
                          >
                          Waze
                      </div>
                      <div 
                        class="clikable d-inline-flex align-items-center attraction-button-size booking rounded-pill fs-6"
                        v-if="activAccommodation( dailyActivites ).link"
                        @click="goToAndTrigerFaceBookQuery( addLabelIfExists( addhttp( activAccommodation( dailyActivites ).link ) ))"
                        >
                          <img  alt="bookingBAtttractionIcon"
                                width="13px"
                                height="18px"                          
                                class="ms-2 attraction-button-image"
                                :src="bookingBAtttractionIcon()"
                          >
                          Booking
                      </div>
                      <!-- TODO ---------------------- we should have an shere                      -->
                      <!-- <div 
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
                        @click="shareThisAttraction( allRide.ride.id , attraction.id )"
                      >
                          <img  alt=""
                                class="ms-2 attraction-button-image" height="24px"
                                :src="shareAtttractionIcon()"
                          >
                          Share
                      </div> -->
                      <div 
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
                        v-if="activAccommodation( dailyActivites ).link"
                        @click="goToBokingDotCom( activAccommodation( dailyActivites ) )"
                      >
                          מחירי לינה
                      </div>
                      <div 
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
                        v-if="activAccommodation( dailyActivites ).link"
                        @click="goToSerchAccommodations( activAccommodation( dailyActivites ) )"
                      >
                          מלונות מומלצים
                      </div>
                  </div>
                  <div class="d-flex flex-wrap align-items-center editin-part gap-2"
                       style="justify-content: flex-end !important;"
                       v-if="compareToUserId( allRide.ride.authorId )"
                       >
                      <div
                        v-if="(!booked( dailyActivites )&&(compareToUserId( allRide.ride.authorId )))"
                        class="clikable d-inline-flex align-items-center attraction-button-size get-your-guide rounded-pill fs-6"
                        @click="goToBaseRideAccommodation( dailyActivites.base_trip_accommodation )"
                        >
                          <img  
                            width="32px"
                            height="32px"
                            alt="XImage"
                            class="ms-2 attraction-button-image"
                            src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FremoveActivity.svg?alt=media&token=29e2e265-3e3a-409c-8066-0f653dfc3399"
                          >
                          לא הוזנו פרטי לינה 
                      </div>
                      <div 
                        class="clikable d-inline-flex align-items-center attraction-button-size primary rounded-pill fs-6"
                        @click="goToRideAccommodation( dailyActivites.accommodation )"
                        v-if="booked( dailyActivites )"
                      >
                          <img  
                            alt="editIcon"
                            class="ms-2 attraction-button-image edit-button"
                            width="32px"
                            height="32px"
                            src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2FeditActivity.svg?alt=media&token=85ea342f-5c95-4a4d-ba3b-fece59e2732c"
                          >
                          עריכה
                      </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="index % 3 === 0" class="bookingBoBorder">
          <BookingImageLabel/>
        </div>
        <div v-if="index==1 && bookingRideToBookingConvertor(rideId)" class="bookingBoBorder">
          <h3 class="booking-offers-header" style="font-size: 24px;
                                                   font-weight: bold;
                                                   color: #D35400;
                                                   padding: 20px;
                                                   background-color: #ECF0F1;
                                                   border-top-right-radius: 1.6rem;
                                                   border-top-left-radius: 1.6rem;
                                                   margin-bottom:0;">
            הזמינו מלון עכשיו עם ביטול חינם!<br>
            שמרו את המחירים המעולים של היום עם גמישות לשינוי תוכניות.<br>
            <button 
              @click="goToAndTrigerFaceBookQuery( addLabelIfExists( addhttp( activAccommodation( dailyActivites ).link ) ))"
              style="font-size: 20px; color: white; background-color: #E74C3C; border: none; padding: 10px 20px; border-radius: 5px; cursor: pointer;">
                הזמינו מקום מושלם עם שקט נפשי
            </button>
          </h3>
          <BookingDiscountLabel/>
        </div>
      </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CollapseText from "@/components/CollapseText";
import BookingDiscountLabel from "@/components/BookingDiscountLabel";
import BookingImageLabel from "@/components/BookingImageLabel";
import config from '../common/config';



export default {
  name: "SingleRide",
  components: {
    CollapseText,
    BookingDiscountLabel,
    BookingImageLabel
  },
  data() {
    return {
      fullscreenImageSrc: null,
    }
  },
  computed: mapGetters([
    "allRide",
    "rideId",
    "isAppLoggedIn",
    "userId",
    "compareToUserId",
    "isMobile",
    "smalerImage",
    "rideUploaded",
  ]),
  methods: {
    ...mapActions([
      "fetchRideWithLoadingPopup",
      "fetchRide",
      "serchAttractionToRide",
      "removeDayFromRide",
      "addNewDayToRide",
      "removeAttractionFromRide",
      "validateAndupdateAppToken",
      "editAttraction",
      "viewAttraction",
      "lowedAttraction",
      "openLoginForm",
      "goToNewAttraction",
      "goToRideAccommodation",
      "goToBaseRideAccommodation",
      "showModalWithParameters",
      "showModal",
      "goToBokingDotCom",
      "goToSerchAccommodations",
      "addLabelIfExists",
      "addhttp",
      "getResizeImageForMobileDevicesCommon"

    ]),
    toggleImageFullscreen(imageSrc) {
      this.fullscreenImageSrc = imageSrc; // Set new image to fullscreen
    },
    closeImageFullscreen() {
      this.fullscreenImageSrc = null;
    },
    loadErrorImage( event, URL ){
      event.target.src = URL
    },
    async showModalAttraction( attraction ) {
      this.showModal( "loadingIcon" );
      await this.lowedAttraction( attraction )
      this.showModal( "attraction" );
    },
    removeDayFromRideInternal(rideId) {
      if (this.compareToUserId( this.allRide.ride.authorId )) {
        this.removeDayFromRide(rideId);
        this.showModalWithParameters( { errorNumber : "the_day_wewre_delited_and_the activites_pushed_to_the_next_day",
                                        type:'textConverter'  } );
      } else {
        this.showModalWithParameters( { errorNumber : "uesr_is_not_authorize_to_edit_this_ride",
                                        type:'textConverter' } );
      }
    },
    addNewDayToRideInternal(rideId) {
      if (this.compareToUserId( this.allRide.ride.authorId )) {
        this.addNewDayToRide(rideId);
        this.showModalWithParameters( { errorNumber : "scroll_down_to_see_the_added_day",
                                        type:'textConverter'  } );

      } else {
        this.showModalWithParameters( { errorNumber : "uesr_is_not_authorize_to_edit_this_ride",
                                        type:'textConverter'  } );
      }
    },
    removeAttractionFromRideInternal(attractionId) {
      if (this.compareToUserId( this.allRide.ride.authorId )) {
        this.showModalWithParameters( { inerModalParameters : { attractionId: attractionId },
                          type: "areYouShureRemovingAttraction" } );
      } else {
        this.showModalWithParameters( { errorNumber : "uesr_is_not_authorize_to_edit_this_ride",
                                        type:'textConverter'  } );
      }
    },
    openWazeLink(attraction) {
      window.open( 'https://www.waze.com/ul?ll=' + attraction.latitude + '%2C' + attraction.longtitude + '&navigate=yes&zoom=17' , '_blank')
    },
    bookingRideToBookingConvertor(rideId){
      return config.config.RIDE_TO_BOKING_ID_CONVERTOR[rideId]
    },
    bookingBAtttractionIcon(){
      return config.config.BOOKING_B_ATTRACTION_ICON
    },
    linkAtttractionIcon(){
      return config.config.LINK_ATTRACTION_ICON
    },
    shareAtttractionIcon(){
      return config.config.SHARE_ATTRACTION_IMAGE
    },
    wazeAtttractionIcon(){
      return config.config.WAZE_ATTRACTION_ICON
    },
    GetYourGuideIconLink() {
      return config.config.GET_YOUR_GUIDE_ATTRACTION_ICON;
    },
    haveGetYourGuideLink(attraction) {
      return config.config.PLACE_ID_TO_GET_YOUR_GUIDE_LINK_CONVERTOR[attraction.placeId];
    },
    openGetYourGuideLink(attraction) {
      window.open( config.config.PLACE_ID_TO_GET_YOUR_GUIDE_LINK_CONVERTOR[attraction.placeId].link , '_blank')

    },
    shareThisAttraction( rideId, attractionId ){
      this.showModalWithParameters( { inerModalParameters : {rideId:rideId, attractionId:attractionId},
                                      type:'shareAttraction' } );
      
    },
    openUpdateAttractionTime(attractionInfo){
      this.$store.commit("setAttractionForChangeingTheDate", attractionInfo);
      if (this.compareToUserId( this.allRide.ride.authorId )) {
        this.showModal( "updateAttractionTime" );
      } else {
        this.showModalWithParameters( { errorNumber : "uesr_is_not_authorize_to_edit_this_ride",
                                        type:'textConverter'  } );
      }
    },
    activAccommodation( dailyActivites ) {
      if ( Object.keys(dailyActivites.accommodation).length ){
        return dailyActivites.accommodation
      } 
      return dailyActivites.base_trip_accommodation
    },
    booked(dailyActivites){
      if ( this.activAccommodation( dailyActivites ).status == "booked" ){
        return true;
      }
      return false;
    },
    getImageHtmlAttributions( htmlCode ){
      return htmlCode.slice(0,3) + 'target="_blank"' + htmlCode.slice(3)
    },
    getMapAccommodationIcon(){
      return config.config.MAP_POINT_ACCOMMODATION;
    },
    goToAndTrigerFaceBookQuery(link){
      if (window.fbq) {
        window.fbq('track', 'Lead');
        window.fbq('track', 'gooToBooking');
        
      }
      this.goTo(link);
    },
    async goTo( link ){
      // let url = "window.open('" + link + "','_blank')"
      // the link at some trip is not have the boking information, soo this is an work arowend top fix it
      var staticLink = await link;
      if("http://undefined"==staticLink){
        staticLink = 'https://www.booking.com?aid=2186348' + '_blank'
      }   
// order=getaway_deals_upsorter
// https://www.booking.com/searchresults.he.html?aid=2420138&lang=he&sid=c9886691859367a1318d52c5014de95d&sb=1&sb_lp=1&order=getaway_deals_upsorter&src=index&src_elem=sb&error_url=https%3A%2F%2Fwww.booking.com%2Findex.he.html%3Faid%3D2420138%26sid%3Dc9886691859367a1318d52c5014de95d%26sb_price_type%3Dtotal%26%26&ss=%D7%91%D7%A8%D7%9C%D7%99%D7%9F&is_ski_area=0&ssne=%D7%91%D7%A8%D7%9C%D7%99%D7%9F&ssne_untouched=%D7%91%D7%A8%D7%9C%D7%99%D7%9F&dest_id=-1746443&dest_type=city&checkin_year=2024&checkin_month=5&checkin_monthday=23&checkout_year=2024&checkout_month=5&checkout_monthday=26&flex_window=0&group_adults=2&group_children=0&no_rooms=1&b_h4u_keep_filters=&from_sf=1
      window.open(staticLink)
      return 
    },
    choseTheRightEditingOption( attraction ){ 
      if(this.compareToUserId( attraction.authorId))
        this.editAttraction( attraction.id )
      else
        this.openUpdateAttractionTime( attraction )
    },
    chouseTheRightWayToAddAtraction( dayIndex ){
      if( this.allRide.ride.is_based_ride && this.compareToUserId( this.allRide.ride.authorId ) ) 
        this.goToNewAttraction( dayIndex )
      else
        this.serchAttractionToRide( dayIndex )
      
    },
 },
//  updated(){
//   (function (d, sc, u) {
//       var s = d.createElement(sc),
//         p = d.getElementsByTagName(sc)[0];
//       s.type = "text/javascript";
//       s.async = true;
//       s.src = u + "?v=" + +new Date();
//       p.parentNode.insertBefore(s, p);
//     })(
//       document,
//       "script",
//       "//aff.bstatic.com/static/affiliate_base/js/flexiproduct.js"
//     );
   
//  },
  created() {
    this.validateAndupdateAppToken();
    this.fetchRide();
  },
};
import '@/views/general.css';
import '@/views/queries.css';



</script>

<style scoped>

.fullscreen-overlay {
  padding-top: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
}

.fullscreen-overlay img {
  max-width: 90%; /* Ensure some margin around the image */
  max-height: 90%;
  object-fit: contain; /* Ensures the image is fully visible */
}

.close-button {
  position: absolute;
  top: 60px;
  right: 20px;
  padding: 10px;
  font-size: 24px;
  color: white;
  background: transparent;
  border: none;
  cursor: pointer;
  z-index: 1001;
  font-weight: 700;
}

.three-colomn .add-activity-add-icon{
font-size: 5rem;
line-height: 3.4rem;
color: #E3A21E;
height: 4rem;
width: 4rem;
padding: 0px 0px;
border: 0.3rem solid #E3A21E;
border-radius: 4.5rem;
}

.three-colomn{
  display: grid;
  column-gap: 2rem;
  row-gap: 14.1rem;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 3rem;
  justify-items: center;
  align-items: center;
}

.three-dots{
  font-size: 10.7rem;
}

.new_information {
  display: none !important;
}

.res-row {
  min-height: 235px;
}

@media (max-width: 575.98px) {
  .res-row {
    min-height: 235px;
  }
}
@media (min-width: 768px) and (max-width: 1023.98px) {
  .res-row {
    min-height: 235px;
  }
}
@media (min-width: 1024.98px) {
  .res-row {
    min-height: 283px;
  }
}
</style>
