import { render, staticRenderFns } from "./AddAccommodationButton.vue?vue&type=template&id=be652f16&scoped=true"
import script from "./AddAccommodationButton.vue?vue&type=script&lang=js"
export * from "./AddAccommodationButton.vue?vue&type=script&lang=js"
import style0 from "./AddAccommodationButton.vue?vue&type=style&index=0&id=be652f16&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be652f16",
  null
  
)

export default component.exports