<template>
  <div
   v-if="rideHeader &&
         rideHeader.ride &&
         rideHeader.ride.id &&
         compareToUserId( rideHeader.ride.authorId ) &&
         allRide &&
         allRide.ride &&
         allRide.ride.is_based_ride"
   class="whatsapp-icon-container-with add-attraction-from-image-container">
    <router-link
      :to="currentUrlWithRideID"
      rel="noopener noreferrer">
      <span>הוספת פעילות</span>
    </router-link>
  </div>
</template>



<script >
import { mapGetters } from "vuex";

export default {
  name: 'AddAtractionFromImage',
  computed: { ...mapGetters([ 'rideHeader',
                              'compareToUserId', 
                              'allRide',
                            ]),
    currentUrlWithRideID() {
      return `/add_images/${this.rideHeader.ride.id}`;
    }
  }

}
</script>

<style scoped>

.add-attraction-from-image-container {
  position: fixed;
  bottom: 190px;
  right: 20px;
  z-index: 999;
}

.add-attraction-from-image-container a {
  display: flex; /* Changed to flex to align icon and text */
  align-items: center; /* Align items vertically */
  background-color: #25d366;
  padding: 10px;
  border-radius: 10px; /* Slightly rounded corners */
  text-decoration: none; /* Remove underline from text */
}

.add-attraction-from-image-container img {
  width: 30px;
  height: 30px;
  margin-left: 10px; /* Add space between icon and text */
}

.add-attraction-from-image-container span {
  color: white; /* Text color */
  font-size: 14px; /* Adjust as needed */
}

</style>
