<template>
  <div>
    <br>
    <gmap-map
      ref="mapRef"
      :center="center"
      :zoom="12"
      :class="[getClass()]"
      @click="handleMapClick"
    >
    </gmap-map>
  </div>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'GlobalMap',
  props: ['passed_class',
          'zoomLevel',
          ],

  computed: mapGetters(['markers', 'center']),
  methods: 
  {
    ...mapActions([ 'geolocate',
                    'setAppMap',
                    'fetchSerchAttractionsByRide',
                    'setOneMarker',
                    'showModalWithParameters',
                    ]),
    getClass(){
      return this.passed_class
    },
    
    handleMapClick(event) {
      if (this.$listeners.userPressedOnMap) {
        this.$emit('userPressedOnMap', event);
      } else {
        console.log('No callback provided for userPressedOnMap event.');
      }
    },
  },
  mounted(){
    this.$refs.mapRef.$mapPromise.then((map) =>{
      this.$store.commit('setAppMap', map)
      if (this.zoomLevel) {
        map.setZoom(this.zoomLevel);
      }

    });    
  }
};
</script>


<style scoped >

.single_ride_map{
  position: relative;
}
</style>
