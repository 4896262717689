<template>
  <div>
    <b-modal
      id="doYouWantToAddThisPointToRide"
      modal-class="modal-cover"
      v-model="isVisible"
      hide-backdrop
      hide-footer
      content-class="shadow-lg"
    >
      <section class="text-center">
        <b-row>
          <b-col cols="12" class="py-2">
            <h3 class="font-weight-medium card-title">
              התמונה שצילמתם נלקחה מהמקום שבחרתם במפה?  
            </h3>
          </b-col>

          <b-col cols="12" class="py-2">
            <p>
              פעולה זואת תוסיף את התמונה שצילמתם בתור הנקודה שבחרתם במפה
            </p>
          </b-col>

          <b-col cols="12" class="py-2">
            <b-btn
              pill
              variant="danger"
              class="px-5 py-2 text-white"
              @click="closePopup"
              >לא זה לא המקום</b-btn
            >
            <b-btn
              pill
              variant="success"
              class="px-5 py-2 text-white"
              @click="addThisPoint"
              >כן זה המקום</b-btn
            >
          </b-col>
        </b-row>
      </section>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from '@/common/config';

export default {
  props: ["value"],
  computed: {
    ...mapGetters([ 'allRide',
                    'inerModalParameters' ]),
    isVisible: {
      set(val) {
        this.$emit("input", val);
      },
      get() {
        return this.value;
      },
    },
  },
  methods: { 
    ...mapActions([ 'addRideToUser',
                    'removeAttractionFromRide',
                    'showModal',
     ]),
    addThisPoint(){
      const place = {type: config.config.PLACE_DATA_TYPE.PLACE_DATA_TYPE_GOOGLE_PLACE_ID, data: { placeId: this.inerModalParameters.placeId } }
      const tempCallback = this.inerModalParameters.callBack;
      // Close the current popup with a slight delay to ensure the callback triggers the next popup
      this.showModal('');
      setTimeout(() => {
        // After closing this popup, call the callback to open the next one
        tempCallback(place);
      }, 100); // 100ms delay to ensure popup sequencing works correctly
    },
    closePopup(){
      this.showModal('')
    },

  },

};
</script>



<style scoped>
.modal.modal-cover .modal-content,
.modal.modal-cover .modal-footer,
.modal.modal-cover .modal-header {
  border: none;
}
.height-50 {
  height: 50px !important;
}
.width-50 {
  width: 50px !important;
}
</style>