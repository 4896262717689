// src/mixins/fileHandlingMixin.js
import EXIF from 'exif-js';
import firebase from 'firebase/app';
import 'firebase/storage';
import heic2any from "heic2any";

export const fileHandlingMixin = {
  methods: {
    onFileChange(event) {
      if (event.target.files.length > 0) {
        this.files = event.target.files;
        this.uploadFiles(() => {
          this.createOrUpdateTripFromImages();
        }, 'attractionsTest');
      } else {
        console.log("No files selected");
      }
    },

    extractExifData(file) {
      return new Promise((resolve, reject) => {
        EXIF.getData(file, function() {
          const exifData = EXIF.getAllTags(this);
          if (Object.keys(exifData).length === 0) {
            reject('No EXIF data found');
          } else {

            resolve({
              dateTaken: exifData.DateTimeOriginal,
              gpsLatitude: exifData.GPSLatitude,
              gpsLatitudeRef: exifData.GPSLatitudeRef,
              gpsLongitude: exifData.GPSLongitude,
              gpsLongitudeRef: exifData.GPSLongitudeRef,
            });
          }
        });
      });
    },

    async convertFileIfNeeded(file ) {
      const unsupportedTypes = {
        "image/heic": "image/jpeg",
        "image/heif": "image/jpeg",
      };
      if (file && unsupportedTypes[file.type]) {
        const targetType = unsupportedTypes[file.type];
        const convertedBlob = await heic2any({ blob: file, toType: targetType });
        return new File([convertedBlob], file.name.replace(/\.(heic|heif)$/, ".jpg"), {
          type: targetType,
        });
      }
      return file; // Return original file if no conversion is needed
    },

    async uploadFiles(callback, fileRefPrefix ) {
    
      this.showModal( "LoadingIconWithUploadStatus" );
      this.$store.commit('setTripFromImagesUploadStatus', { totalFiles: this.files.length, uploadedFiles: 0 });
      const promises = [];
      for (let i = 0; i < this.files.length; i++) {
       
        const file = await this.convertFileIfNeeded( this.files[i] );
                
        
        var today=new Date();
        let myTime = today.toUTCString().split(', ')[ 1 ].split(' ').join('_')
        const fileRef = `${fileRefPrefix}/${myTime}${file.name}`;  // Use the dynamic prefix
        const storageRef = firebase.storage().ref(fileRef);
        const uploadTask = storageRef.put(file);

        promises.push(
          uploadTask
            .then(async () => {
              let exifData = {};
              try {
                exifData = await this.extractExifData(file);
              } catch (error) {
                console.error("Error extracting EXIF data:", error);
              }
              const downloadURL = await storageRef.getDownloadURL();
              const metadata = {
                filename: file.name,
                size: file.size,
                type: file.type,
                dateTaken: exifData.dateTaken || null,
                location: exifData.gpsLatitude && exifData.gpsLongitude && exifData.gpsLatitudeRef && exifData.gpsLongitudeRef
                  ? {
                      latitude: exifData.gpsLatitude,
                      latitudeRef: exifData.gpsLatitudeRef,
                      longitude: exifData.gpsLongitude,
                      longitudeRef: exifData.gpsLongitudeRef,
                    }
                  : { latitude: null, longitude: null },
                uploadedAt: new Date().toLocaleString(),
                url: downloadURL,
              };

              this.$store.commit('pushToAtractionsMetadataList', metadata );
              this.$store.commit('incrementUploadedFiles');
            })
            .catch((error) => {
              console.error("Error uploading file:", error);
            })
        );
      }

      // When all uploads are done, call the callback
      Promise.all(promises)
        .then(() => {
          this.showModal("");
          this.$store.commit('sortAtractionsMetadataListByDate');
          if (callback && typeof callback === 'function') {
            callback();
          }
        })
        .catch((error) => {
          this.showModal("");
          console.error("There was an error uploading the files!", error);
        });
    },
  }
};
