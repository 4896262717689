<!-- addAccommodationFromImage.vue -->
<template>
  <BaseFromImage 
    dateTitle="הוספת מקום לינה"
    :addToRide="addToRide"
  />
</template>

<script>
import BaseFromImage from '@/components/BaseFromImage.vue';
import { mapActions } from 'vuex';
import { UPLOAD_RIDE_STATES } from '@/constants/uploadRideStates.js';

export default {
  components: {
    BaseFromImage, // Register the component here
  },
  methods: {
    ...mapActions([ 'addAccommodationToRideFromImage',
                    'sendingImageMetaData',
    ]),
    async addToRide(place) {
      await this.addAccommodationToRideFromImage(place);
    },
  },
  mounted() {
    this.sendingImageMetaData();
    this.$store.commit('setUploadRideState', UPLOAD_RIDE_STATES.ADDING_ACCOMMODATIONS_FROM_IMAGES);
  },
};
</script>