var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.rideHeader &&
       _vm.rideHeader.ride &&
       _vm.rideHeader.ride.id &&
       _vm.compareToUserId( _vm.rideHeader.ride.authorId ) &&
       _vm.allRide &&
       _vm.allRide.ride &&
       _vm.allRide.ride.is_based_ride)?_c('div',{staticClass:"whatsapp-icon-container-with add-attraction-from-image-container"},[_c('router-link',{attrs:{"to":_vm.currentUrlWithRideID,"rel":"noopener noreferrer"}},[_c('span',[_vm._v("הוספת לינה")])])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }