<template>
<div class="internal-bady" >
<WhatsappChet/>
<NavBar/>
<!-- <div id="header"></div> -->
<div id="top"></div>
<div class="px-3 px-md-4" id="welcome-section">
    <div 
        :style="mainHomeImageStyle"
        class="container-fluid bg-welcome rounded-5 d-flex align-items-center">
        <div class="container">
            <div class="row  position-index">
                <div class="col col-lg-8 col-xl-6 d-flex flex-column gap-3 gap-md-5">
                    <h1 class="text-center text-welcome text-white mb-0">
                        בואו לתכנן את החופשה 
                        <!-- <br> -->
                        המושלמת למשפחה שלכם
                        </h1>
                    <div class="input-group mb-0 mb-md-3">
                        <div class="input-icon w-100">
                            <i class="fa-solid fa-plane fs-3 pe-2">
                              <img
                                src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fplane_search_trip.png?alt=media&token=abba34a2-a85a-4044-88f0-96eb579cd0bb"
                                height="31px"
                                alt=""
                                class="clickable fa-solid image-31px fa-plane_image fs-3 pe-2"
                                @click="goToSearchRideByName">  
                            </i>
                            <gmap-autocomplete
                                @place_changed="searchRidesByGeoPoint"
                                :select-first-on-enter="true"
                                v-on:input="handleInput"
                                class="form-control pading_top_and_butom_3 text-end py-3 fs-5 rounded-pill border-0 bg-white shadow "
                                placeholder="הכניסו את היעד שלכם!"
                                :options="{
                                fields: ['geometry'],
                              }"
                            >
                            </gmap-autocomplete>
                        </div>
                    </div>
                    <div>
                        <p class="text-end fs-4 fw-semibold text-white mb-1 mb-md-2 text-align-center">
                            צפו במאגר החופשות שמשפחות שיתפו
                        </p>
                        <div class="justify-content-space-around d-flex gap-2 gap-md-3">
                            <button 
                                class="btn btn-warning px-3 px-md-4 py-3 rounded-pill border border-2 border-white fw-semibold fs-5"
                                @click="goToSerchattractionPage()"
                            >
                                צפו בחופשות
                            </button>
                            <button 
                                class="btn btn-primary px-3 px-md-4 py-3 rounded-pill border border-2 border-white fw-semibold fs-5"
                                @click="internalOpenRideInformationPage()"
                            >
                                שתפו חופשה
                            </button>
                        </div>
                    </div>
                    <div class="d-none d-xl-block">
                        <p class="text-end fs-4 fw-semibold text-white">
                            בשיתוף עם:
                        </p>
                        <div class="glass-card d-flex gap-3 justify-content-center align-items-center p-4 rounded-4">
                            <img 
                                alt="" 
                                class="" 
                                height="30px" 
                                src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fwaze-logo-1400x444.png?alt=media&token=ba31f446-0eba-4560-9850-fc8784e519e8">
                            <img 
                                alt=""
                                class=""
                                height="30px"
                                src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fbooking-logo.png?alt=media&token=59c22950-fa84-44a9-86e8-80b1410cc3e0">
                            <img 
                                alt=""
                                class=""
                                height="30px"
                                src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fskyscanner-logo.png?alt=media&token=56954b1b-026d-4d7f-8c5f-a71d03c945e8">
                            <img 
                                alt=""
                                class=""
                                height="30px"
                                src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fgoogle-logo.png?alt=media&token=598d5fab-e79e-4c70-8bef-a146f6d22e66">
                        </div>
                    </div>
                    <div class="d-block d-xl-none">
                        <p class="text-end fs-4 fw-semibold text-white mb-1">
                            בשיתוף עם:
                        </p>
                        <div class="glass-card d-flex gap-3 justify-content-center align-items-center p-3 p-md-5 rounded-5">
                            <div class="row align-items-center display_ruby">
                                <div class="col-6">
                                    <img 
                                        alt=""
                                        class="img-fluid"
                                        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fwaze-logo-1400x444.png?alt=media&token=ba31f446-0eba-4560-9850-fc8784e519e8"></div>
                                <div class="col-6">
                                    <img 
                                        alt=""
                                        class="img-fluid"
                                        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fbooking-logo.png?alt=media&token=59c22950-fa84-44a9-86e8-80b1410cc3e0">
                                </div>
                                <div class="col-6 mb-3"><img
                                    alt=""
                                    class="img-fluid"
                                    src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fskyscanner-logo.png?alt=media&token=56954b1b-026d-4d7f-8c5f-a71d03c945e8">
                                </div>
                                <div class="col-6 mb-3">
                                    <img
                                        alt=""
                                        class="img-fluid"
                                        src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fgoogle-logo.png?alt=media&token=598d5fab-e79e-4c70-8bef-a146f6d22e66">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="py-4 py-md-5" id="adventures-trip">
    <div class="container">
        <h2 class="text-center fw-bold">התחילו בבחירת חופשה כבסיס לתכנון</h2>
        <TripsImagePresentation/>
        <div class="d-flex justify-content-center">
            <button 
                class="btn btn-warning px-5 py-3 rounded-pill fw-semibold fs-5"
                @click="goToSerchattractionPage()"
            >
                צפו במאגר החופשות
            </button>
        </div>
    </div>
</div>
<HowItWorksNew/>

<div class="container-fluid py-5 position-relative overflow-hidden" id="what-can-do">
    <div>
        <img alt="" class="background-image background-image-max-with-none" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>
    <div class="container py-5">
        <div class="d-flex justify-content-center">
            <div class="w-75 full-width">
                <h2 class="text-center fw-bold black margin-for-h2">
                    מה אפשר לעשות ב - <span class="text-primary">Trip</span><span class="text-secondary">planner</span>
                </h2>
                <p class="text-muted text-center fs-6 what_can_be_done_explanation">
                    כשיש לכם את כל המידע שאתם צריכים זה פשוט לטייל חכם.
                    <span class="text-primary">Trip</span><span class="text-secondary">planner</span>
                    מפקס אתכם על התוכנית לחופשה שבאמת מתאימה למשפחה שלכם
                </p>
            </div>
        </div>
        <div class="row justify-content-center align-items-stretch">
            <div class="col-sm-6 col col-lg-4 mb-2 mb-md-0 d-flex">
                <div class="card border-0 rounded-5 shadow-sm p-md-2 w-100 d-flex flex-column">
                    <div class="card-body d-flex flex-column gap-md-3 gap-2 text-center flex-grow-1">
                        <div class="rounded-2 bg-card"
                             style="height: 200px; background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fview-trip.jpg?alt=media&token=a957844f-8a7b-4d0d-b828-809f46a9a702')"></div>
                        <div class="flex-grow-1 d-flex flex-column justify-content-between">
                            <h4 class="fw-bold mb-0 black">
                                מתכננים חופשה
                            </h4>
                            <p class="fs-6 mb-0 text-muted what_can_be_done_explanation">
                                בואו להשתמש בחופשות שמשפחות אמיתיות עשו ותשתמשו בניסיון שלהם
                            </p>
                        </div>
                        <button 
                            class="w-100 btn btn-warning px-5 py-3 rounded-pill fw-semibold fs-5 d-flex align-items-center justify-content-center mt-auto"
                            @click="goToSerchattractionPage()"
                        >
                            צפו בחופשות
                            <i class="ms-2 fa-solid fa-face-smile-beam fs-4"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col col-lg-4 mb-2 mb-md-0 d-flex">
                <div class="card border-0 rounded-5 shadow-sm p-md-2 w-100 d-flex flex-column">
                    <div class="card-body d-flex flex-column gap-md-3 gap-2 text-center flex-grow-1">
                        <div class="rounded-2 bg-card"
                             style="height: 200px; background-image: url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fshare-trip.jpg?alt=media&token=20e32b74-5566-49af-ac8a-2eb62829f20f')"></div>
                        <div class="flex-grow-1 d-flex flex-column justify-content-between">
                            <h4 class="fw-bold mb-0 black">
                                חזרתם מחופשה
                            </h4>
                            <p class="fs-6 mb-0 text-muted what_can_be_done_explanation">
                                חזרתם מחופשה מושלמת
                                <br>
                                זה הזמן להחזיר לקהילה ולאפשר גם לאחרים לעשות את החופשה שלכם
                            </p>
                        </div>
                        <button 
                            class="w-100 btn btn-primary px-5 py-3 rounded-pill fw-semibold fs-5 d-flex align-items-center justify-content-center mt-auto"
                            @click="internalOpenRideInformationPage()"
                        >
                            העלאת חופשה
                            <i class="ms-2 fa-solid fa-share-nodes fs-4"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container py-3 py-md-5">
        <div class=" d-flex justify-content-center">
            <div class="w-75 full-width">
                <h2 class="text-center fw-bold">מסלולים מומלצים</h2>
                <p class="text-muted text-center fs-6 what_can_be_done_explanation">
                  אין חכם כבעל ניסיון - טיפים ממשפחות שעשו את החופשה שאתם מתכננים לעשות.
                  <br>
                  מסלולים אמיתיים של משפחות שהעלו את החופשה שלהם לאתר כדי שאתם תוכלו להשתמש בזה כבסיס לתכנון שלכם
                </p>
            </div>
        </div>
        <div class="row my-3 my-md-5">
          <RecommendedTrips/>
        </div>
        <!-- <div class="row my-3 my-md-5" id="recommended-trips"></div> -->
        <div class="d-flex justify-content-center pb-5">
            <button 
                class="btn btn-primary px-5 py-3 rounded-pill fw-semibold fs-5 z-2"
                @click="goToSerchattractionPage()"
            >
              צפו בחופשות מוצלחות
            </button>
        </div>
    </div>
</div>

<div id="shared-trips">
    <div class="container-fluid py-5 bg-light-blue">
<!--         <h2 class="text-center fw-bold">Recently shared trips</h2>
        <div class="carousel slide carousel-height" data-bs-ride="carousel"
             id="carouselExampleAutoplaying">
            <div class="carousel-inner container-md p-0 d-flex h-100">
                <div class="carousel-item active p-2 py-md-4 px-md-3 my-auto">
                    <div class="card shadow-sm border-0 rounded-5 p-1 p-md-3">
                        <div class="card-body d-flex align-items-center">
                            <div class="row">
                                <div class="col-md-5 mb-md-0 mb-3">
                                    <div class="bg-card rounded-2 review-image"
                                         style="background-image: url('assets/images/homepage/starter-trips/trip2.png')"></div>
                                </div>
                                <div class="col-md-7 d-flex flex-column gap-2 gap-md-3">
                                    <h4 class="text-end fs-4">Flachau - two weeks in Austria</h4>
                                    <div class="d-flex justify-content-end gap-2">
                                        <div class="bg-light-blue d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center fs-6">
                                            <i class="fa-solid fa-calendar-days text-primary fs-5 me-2"></i> 2 Weeks
                                        </div>
                                        <div class="bg-light-orange d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center fs-6">
                                            <i class="fa-solid fa-map-pin text-orange fs-5 me-2"></i> Austria
                                        </div>
                                    </div>
                                    <div>
                                        <p class="text-muted text-end fs-6">
                                            After countless searches, readings and research, we managed to put together
                                            the
                                            trip
                                            that would be perfect for our family in Austria. We decided on a trip area
                                            (Salzburg
                                            region) and a place to stay from where we went on a star trip. The weather
                                            was
                                            changeable. In Vienna it is hot during the day, cooler during the night, in
                                            the
                                            Salzburg
                                            region and on the trips where we went to the mountains it was cold and
                                            sometimes
                                            rainy.
                                            <br><br>
                                            <i>Partnered by Avshalom Katz</i>
                                            <br>
                                            01.09.2019-20.08.2019
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="carousel-item py-md-4 px-md-3 my-auto">
                    <div class="card shadow-sm border-0 rounded-5 p-2 p-md-3">
                        <div class="card-body d-flex align-items-center">
                            <div class="row">
                                <div class="col-md-5 mb-3">
                                    <div class="bg-card rounded-2 review-image"
                                         style="background-image: url('assets/images/homepage/starter-trips/trip3.png')"></div>
                                </div>
                                <div class="col-md-7 d-flex flex-column gap-2 gap-md-3">
                                    <h4 class="text-end fs-4">Two weeks in Austria - Salzburg and Tyrol area
                                    </h4>
                                    <div class="d-flex justify-content-end gap-2">
                                        <div class="bg-light-blue d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center fs-6">
                                            <i class="fa-solid fa-calendar-days text-primary fs-5 me-2"></i> 2 Weeks
                                        </div>
                                        <div class="bg-light-orange d-inline-flex px-3 py-2 rounded-pill align-items-center justify-content-center fs-6">
                                            <i class="fa-solid fa-map-pin text-orange fs-5 me-2"></i> Austria
                                        </div>
                                    </div>
                                    <div>
                                        <p class="text-muted text-end fs-6">
                                            Hi, I'm Ashkar (Erblich-Brifman) and I'm a children's and youth author and
                                            host of online writing workshops for children. In addition, I am married and
                                            the mother of three daughters. Our family is an avid traveler. I chose to
                                            share with you and share with you our trip to Austria.
                                            <br><br>
                                            <i>Partnered by Ascher Erblich-Brifman</i>
                                            <br>
                                            26.07.2021-12.07.2021
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="carousel-control-prev" data-bs-slide="prev"
                    data-bs-target="#carouselExampleAutoplaying"
                    type="button">
                <span aria-hidden="true" class="carousel-control-prev-icon"></span>
                <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" data-bs-slide="next" data-bs-target="#carouselExampleAutoplaying"
                    type="button">
                <span aria-hidden="true" class="carousel-control-next-icon"></span>
                <span class="visually-hidden">Next</span>
            </button>
        </div> -->
    </div>
</div>

<div class="container-fluid position-relative overflow-hidden" id="why-choose-tripplanner">
    <div>
        <img alt="" class="background-image" src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fcircles-background.png?alt=media&token=d420088e-66c6-4c95-bd3b-2f218dd36aba">
    </div>
    <div class="container py-5">
        <h2 class="text-center fw-bold py-5">למה לתכנן עם  <span class="text-primary">Trip</span><span
                class="text-secondary">planner</span>?
        </h2>
        <div class="row align-items-stretch py-4">
            <div class="col-md-6 mb-3">
                <div class="card border-0 rounded-5 shadow-sm p-md-2 h-100">
                    <div class="card-body d-flex flex-column gap-md-3 gap-2 text-center">
                        <img alt="" class="image-how-it-works"
                             src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fsearch-trips.svg?alt=media&token=000e4a8d-2550-484d-b580-b9dda1f4eebd">
                        <h4 class="fw-bold mb-0">
                            כל החופשות נעשו על ידי משפחות כמוכם
                        </h4>
                        <p class="fs-6 mb-0 text-muted what_can_be_done_explanation">
                            אין כמו לקבל המלצות ממשפחות שעשו את החופשה הזואת לפניכם.
                            אין צורך להמציא את הגלגל מחדש, החופשה כבר תוכננה ונבדקה.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="card border-0 rounded-5 shadow-sm p-md-2 h-100">
                    <div class="card-body d-flex flex-column gap-md-3 gap-2 text-center">
                        <img alt="" class="image-how-it-works"
                             src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Flarge-database.svg?alt=media&token=3560c0aa-ba18-4e39-906d-257ffc736401">
                        <h4 class="fw-bold mb-0">
                            מאגר גדול של חופשות ופעילויות
                        </h4>
                        <p class="fs-6 mb-0 text-muted what_can_be_done_explanation">
                          יש לנו מאגר גדול של חופשות שמשפחות העלו לאתר כדי שתוכלו להשתמש בהן.
                          מתוך מאגר החופשות יש לנו מאגר גדול יותר של פעילויות שניתן להוסיף עבור כל חופשה שתבחרו.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="card border-0 rounded-5 shadow-sm p-md-2 h-100">
                    <div class="card-body d-flex flex-column gap-md-3 gap-2 text-center">
                        <img alt="" class="image-how-it-works"
                             src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fbooking-of-hotels.svg?alt=media&token=e9025e97-32ea-44ad-ade7-b958af1fff86">
                        <h4 class="fw-bold mb-0">
                            חלוקה ברורה של ימים לאיזורים
                        </h4>
                        <p class="fs-6 mb-0 text-muted what_can_be_done_explanation">
                            חלוקה ברורה של כמות הימים לכל איזור במהלך החופשה.
                            המלצה של מקומות לינה עבור כל חלק בחופשה.
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 mb-3">
                <div class="card border-0 rounded-5 shadow-sm p-md-2 h-100">
                    <div class="card-body d-flex flex-column gap-md-3 gap-2 text-center">
                        <img alt="" class="image-how-it-works"
                             src="https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Feasy-editing.svg?alt=media&token=37c37af9-9590-45d2-b86e-df4c36d5b15b">
                        <h4 class="fw-bold mb-0">
                            דרך פשוטה לתכנן טיול
                        </h4>
                        <p class="fs-6 mb-0 text-muted what_can_be_done_explanation">
                            אפשר להוריד או להוסיף פעילויות ממאגר הפעילויות לחופשה שבחרתם.
                            אפשר לשנות את אורך החופשה.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-center pb-5">
            <button 
                class="btn btn-primary px-5 py-3 rounded-pill fw-semibold fs-5 z-2"
                @click="goToSerchattractionPage()"
            >
                צפו בחופשות מוצלחות
            </button>
        </div>
    </div>
</div>
<div class="container-fluid bg-light-blue py-md-5">
    <div class="container my-md-5 my-3 py-3" id="collaborators">
      <CollaboratorsWith/>
    </div>
</div>

<!-- <div id="footer"> -->
  <PageFooterNew/>
<!-- </div> -->
</div>
</template>


<script src="/src/plugins/component-import.js"></script>
<script src="/src/plugins/load-starter-trips.js"></script>
<script src="/src/plugins/load-recommended-trips.js"></script>


<script>
import HowItWorksNew from "@/components/HowItWorksNew"
import NavBar from "@/components/NavBar.vue"
import TripsImagePresentation from "@/components/TripsImagePresentation.vue"
import RecommendedTrips from "@/components/RecommendedTrips.vue"
import CollaboratorsWith from "@/components/CollaboratorsWith.vue"
import PageFooterNew from "@/components/PageFooterNew.vue"
import WhatsappChet from "@/components/WhatsappChet";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NewHomePage",
  components: {
    HowItWorksNew,
    NavBar,
    TripsImagePresentation,
    RecommendedTrips,
    CollaboratorsWith,
    PageFooterNew,
    WhatsappChet,
    
  },
  data() {
    return {
      mainHomeImageStyle: {
          backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fwelcome-trip.jpg?alt=media&token=0454fe77-65ec-4ece-8c99-6d978d2a7bbd')`,
      },
      latestRides: [{
              imagePath: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/1_30_Mar_2021_16%3A34%3A03_GMT?alt=media&token=983aa4d1-62ae-4436-8518-08f9e2558d0a',
              header: 'Flachau -  שבועיים באוסטריה',
              openText: "אחרי אינספור חיפושים, קריאות וחקר, הצלחנו להרכיב את הטיול שיהיה מושלם עבור משפחתנו באוסטריה.\
                        החלטנו על אזור טיול (חבל זלצבורג) ועל מקום לינה שממנו יצאנו לטיול כוכב.\
                        מזג האוויר היה הפכפך. בוינה חם במהלך היום, קריר יותר במהלך הלילה, בחבל זלצבורג ובטיולים בהם עלינו להרים היה קר וגם גשום לעיתים.",
              route: '/single_ride_with_map/1',
              sharedBy: 'שותף ע״י אבשלום כץ',
              dates: '01.09.2019-20.08.2019',
          },
          {
              imagePath: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/ride72_homepage%20(1).jpg?alt=media&token=9cb847dd-3f58-44ae-b41e-438b2db42cda',
              header: 'שבועיים באוסטריה- אזור זלצבורג וטירול',
              openText: "הי, אני אשכר (ארבליך-בריפמן) ואני סופרת ילדים ונוער ומנחת סדנאות כתיבה אינטרנטיות לילדים.\
                        מעבר לכך, אני נשואה ואמא לשלוש בנות. המשפחה שלנו חובבת מושבעת של טיולים. בחרתי לחלוק איתכם ולשתף אתכם בטיולנו לאוסטריה.",
              route: '/single_ride_with_map/72',
              sharedBy: 'שותף ע״י אשכר ארבליך-בריפמן',
              dates: '26.07.2021-12.07.2021',
          },
          {
              imagePath: 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/attractions%2F183_27_Aug_2022_17%3A17%3A40_GMT?alt=media&token=ed9f07cf-e111-4805-92c6-e0456e2ef8ff',
              header: 'שבועיים בדרום פולין והרי הטטרה בסלובקיה',
              openText: "טיול משפחתי שחיכינו לו הרבה זמן (הטיול הראשון מאז הקורונה).\
                         זוג הורים + ארבעה ילדים ביגלאי 4-11.\
                         אוהבים טבע, אטרקציות ומסלולים קלילים, כך שאחרי הרבה מחשבה החלטנו לטייל בדרום פולין ולשלב גיחות לסלובקיה (בפועל היינו שם שלושה ימים)...)\
                       ",
              route: '/single_ride_with_map/183',
              sharedBy: 'שותף ע״י אבשלום כץ ',
              dates: '25.08.2022-11.08.2022',
          },
      ],
      userSearchInput: "",
      gMapPlace: "",
      jumbotronHeight: "",
      alignLocation: "end",
      value1: 2,
      value2: 2,
      value3: 0,
      selectsOptions: {
        first: {
          label: { text: "ימים" },
          options: ["1", "2", "3", "4", "5", "6", "7", "14", "21"]
        },
        second: {
          label: { text: "םישנא" },
          options: ["choice 1", "choice 2", "choice 3"]
        },
        third: {
          label: { text: "םוקימ ןזה" },
          options: ["choice 1", "choice 2", "choice 3"]
        }
      },
      selects: [
        [{ text: "םימי", value: null }, "choice 1", "choice 2", "choice 3"],
        [{ text: "םישנא", value: null }, "choice 1", "choice 2", "choice 3"],
        [{ text: "םוקימ ןזה", value: null }, "choice 1", "choice 2", "choice 3"]
      ]
    };
  },
  computed: mapGetters(["isAppLoggedIn",
                        "isMobile",
                        ]),
  methods: {
    ...mapActions(["openRideInformationPage",
                   "searchRidesByGeoPoint",
                   "goToSerchattractionPage",
                   "showModalWithParameters",
                   "fetchGlobalRidesByName",
                   ]),
    handleInput(event) {
      this.userSearchInput = event.target.value
    },
    goToSearchRideByName(){
      if(this.userSearchInput == ""){
        this.goToSerchattractionPage();
        return
      }
      const link = '/search_ride_by_name/' + this.userSearchInput
      this.$router.push(link)
    },
    internalOpenRideInformationPage(){
      this.$router.push('/insert_new_ride_from_google_photos')
    },
    getImageHeight(imageLoadedCb) {
      let image = new Image();
      image.onload = () => {
        imageLoadedCb(image.height);
      };
      image.src = "https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/gui_images%2Fheader.png?alt=media&token=350c3bd1-ef0e-4d52-99cf-465796ffae0b"
    },
    getHeight(height) {
      this.jumbotronHeight = height + "px";
    },

  },
  mounted() {
    this.getImageHeight(this.getHeight);
    if( window.innerWidth > 768 ){
      this.alignLocation = 'end';
    }
    else{
      this.alignLocation = 'center';
    }
  },
  metaInfo() {
    return {
      title: 'TripPlanner',
      link: [
        { property: 'og:title', content: 'TripPlanner' },
        { property: 'og:description', content: 'מאגר טיול גדול של משפחות ששיתפו את המסלולים שלהם' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: `https://tripplanner.co.il` },
        // { property: 'og:image', content: 'https://tripplanner.co.il/image-url.jpg' },
        { name: 'description', content: 'אתר לשיתוף טיולים וצפיה בטיולים של מטיילים ישראלים ברחבי העולם' },
        // { name: 'keywords', content: 'keyword1, keyword2, keyword3' },
        // { rel: 'canonical', href: "https://tripplanner.co.il" }
      ]
    };
  },
  created() {
    if (this.isMobile){
      var resizedUrl = 'https://firebasestorage.googleapis.com/v0/b/tripplanner-308b3.appspot.com/o/page_images%2Fhome_page_image_mobile_1.jpg?alt=media&token=72aaafda-25e5-471f-9bc2-44508cf0c568';
      this.mainHomeImageStyle = { backgroundImage: `url('${resizedUrl}')` }
    }
  },
};
</script>

<style scoped>

#welcome-section {
    padding-top: 98px;
    padding-bottom: 24px;
    height: 100dvh;
}

.text-welcome {
    font-size: 72px;
    font-weight: 800;
}

.input-icon {
    position: relative;
}

.input-icon input {
    padding-right: 6.4em;
    padding-left: 6.4em;
}

.fs-5 {
    font-size: 2rem !important;
}

.fa-plane_image{
  height: 31px;
}

.image-31px {
  height: 31px;
  width: 31px;
}

.py-3 {
    padding-bottom: 3rem !important;
    padding-top: 3rem !important;
}
.input-icon .fa-plane {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: linear-gradient(45deg, #34D0B6, #07A0C3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bg-welcome {
    position: relative;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: 100%;
}

.bg-welcome::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 32px;
    background: linear-gradient(45deg, rgba(43, 43, 43, 0), rgba(30, 30, 30, 0.8));
    z-index: 1;
}
.rounded-4 {
  border-radius: 1.6rem !important;
}

.gap-3{
    gap: 1.6rem !important;
}
.p-4 {
    padding: 2.4rem !important;
}

.rounded-5{
	border-radius: 32px !important;
}

/* .gap-3{
  gap: 5rem !important;
} */

.gap-2{
  gap: .8rem !important;
}

@media (min-width: 768px){
  .gap-md-5{
    gap: 5rem !important;
  }
  .px-md-4 {
      padding-right: 2.4rem !important;
      padding-left: 2.4rem !important;
  }
    .py-md-5 {
        padding-top: 4.8rem !important;
        padding-bottom: 4.8rem !important;
    }

}

.margin-for-h2 {
    margin-bottom: 0.8rem !important;
}
.mb-md-2, .my-md-2 {
    margin-bottom: 2rem !important;
}
.position-index {
    position: relative;
    z-index: 2; /* Higher than the overlay */
}

.glass-card {
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(8px);
}

.text-secondary {
    color: #0D2F29 !important;
}

.carousel-height {
    height: 450px;
}

.review-image {
    width: 100%;
    height: 100%;
}


.col-6 {
    flex: 0 0 40%;
    width: 40%;
}

@media (max-width: 575.98px) {
    .input-icon input {
        padding-right: 3.4em;
        padding-left: 0;
    }

    .text-welcome {
        font-size: 32px;
        font-weight: 800;
    }

    #welcome-section {
        padding-top: 74px;
        padding-bottom: 16px;
        height: 100dvh;
        min-height: 100dvh;
    }


    .full-width {
        width: 100% !important;
    }

    .carousel-height {
        height: 620px;
    }

    .review-image {
        width: 100%;
        height: 150px;
    }
    .col-6 {
      flex: 0 0 35%;
      width: 35%;
    }

}
.display_ruby{
    justify-content: center;
}

.fs-4 {
  font-size: 2.4rem !important;
}

.py-3 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}
.px-3 {
    padding-right: 2.4rem !important;
    padding-left: 2.4rem !important;
}

.pading_top_and_butom_3{
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.fw-semibold {
    font-weight: 600 !important;
}
p {
    margin-top: 0;
    margin-bottom: 1.6rem;
}

img{
  height: revert-layer;
}
.fw-bold {
    font-weight: 700 !important;
}

@media (min-width: 1200px) {
    .h2, h2 {
        font-size: 3.6rem;
    }
}








body {
    font-family: "Nunito", sans-serif;
    font-optical-sizing: auto;
    color: #1e1e1e;
}

.primary {
    background: linear-gradient(45deg, #34D0B6, #07A0C3);
    color: white;
}

.text-primary {
    background-image: linear-gradient(45deg, #34D0B6, #07A0C3) !important;
    color: transparent !important;
    -webkit-background-clip: text !important;
    -moz-background-clip: text !important;
    background-clip: text !important;
}

.btn-primary {
    background: linear-gradient(45deg, #34D0B6, #07A0C3);
    color: white;
    border: 0;
}

.btn-primary:hover {
    background: linear-gradient(45deg, #39e1c5, #08add3);
    color: white;
    border: 0;
}

.btn-warning {
    background: linear-gradient(45deg, #E0BE36, #DD6E42);
    color: white;
    border: 0;
}

.btn-warning:hover {
    background: linear-gradient(45deg, #e8c538, #ee7748);
    color: white;
    border: 0;
}

.transparent-background-primary {
    background-color: rgba(7, 160, 195, 0.1) !important;
}

.font-64 {
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    font-size: 64px !important;
    font-weight: 800;
    color: #07A0C3;
    opacity: 20%;
    line-height: 64px !important;
}

#banner {
    height: 150px;
    /* background-image: url('/assets/images/banner-background.png'); */
    background-repeat: no-repeat;
    /*background-attachment: fixed;*/
    background-size: cover;
    color: white;
}


.vl {
    border-left: 2px solid rgba(7, 160, 195, 0.5);
    height: 100%;
}

.color-primary {
    color: #07A0C3 !important;
    border: 0
}

.text-orange {
    color: #DD6E42;
}

#review {
    background-color: rgba(7, 160, 195, 0.1);
    border-radius: 0 0 32px 32px;
}

#traveler-image {
    height: 100px;
    width: 100px;
    /* background-image: url("/assets/images/traveler-image.png"); */
    background-repeat: no-repeat;
    background-size: cover;
}

.fs-7 {
    font-size: 14px !important;
}

#map-mobile {
    height: 100% !important;
    width: 100% !important;
    /* background-image: url("/assets/images/map.svg"); */
    background-repeat: no-repeat;
    background-size: cover;
}

#scroll-map {
    position: sticky;
    top: 70px;
    height: 100vh;
    z-index: 1; /* Ensure the map stays above other content */
}

#location1 {
    height: 140px;
    width: 140px;
    /* background-image: url("/assets/images/location1.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

#location2 {
    height: 140px;
    width: 140px;
    /* background-image: url("/assets/images/location2.jpg"); */
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
}

.border-primary {
    border-color: rgba(7, 160, 195, 0.3) !important;
}

.content {
    max-height: calc(1.5em * 2);
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    word-wrap: break-word;
    opacity: 60%;
}


.show-all-content {
    max-height: none; /* Show all content */
    -webkit-line-clamp: initial;
}

.stars {
    color: #FFD83B;
}

.waze {
    background-color: #05C8F7 !important;
    color: white;
}

.booking {
    background-color: #003580 !important;
    color: white;
}

.get-your-guide {
    background-color: #FF5532 !important;
    color: white;
}

.whats-up {
    background-color: #25D366 !important;
    color: white;
}

.whats-up:hover {
    background-color: #22c75f !important;
    color: white;
}


.bg-footer {
    background-color: #0D2F29;
}

.floating-buttons {
    position: fixed;
    bottom: 20px; /* Adjust the bottom margin as needed */
    right: 20px; /* Adjust the right margin as needed */
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    z-index: 2 !important;
}

/* .carousel-control-prev-icon {
    background-image: url("../assets/images/arrows/carousel-control-prev-icon.svg");
}

.carousel-control-next-icon {
    background-image: url("../assets/images/arrows/carousel-control-next-icon.svg");
} */

.step-number-badge {
    height: 54px;
    width: 54px;
    top: 16px;
    right: 16px;
    color: #DD6E42;
    font-weight: 900;
    background-color: rgba(221, 110, 66, 0.25);
}

.bg-light-blue {
    background-color: #E6F5F9 !important;
}

.bg-light-orange {
    background-color: rgba(221, 110, 66, 0.15);
}

.background-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
}
.background-image-max-with-none {
  max-width: none;
}

.bg-card {
    background-size: cover !important;
    background-position: center center;
    background-repeat: no-repeat;
}

.image-how-it-works {
    height: 150px !important;
}

.accordion-button:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 white;
}

.accordion {
    --bs-accordion-border-color: transparent;
}

@media (max-width: 575.98px) {
    .fs-7 {
        font-size: 14px !important;
    }

    .fs-6 {
        font-size: 15px !important;
    }

    .fs-5 {
        font-size: 15px !important;
    }

    .fs-4 {
        font-size: 18px !important;
    }

    #map {
        width: 100% !important;
    }

    #location1 {
        height: 90px;
        width: 90px;
        /* background-image: url("/assets/images/location1.jpg"); */
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
    }

    #location2 {
        height: 90px;
        width: 90px;
        /* background-image: url("/assets/images/location2.jpg"); */
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
    }

    .get-your-guide-image {
        height: 16px !important;
    }

    .google-logo {
        height: 50px !important;
    }

    .waze-logo {
        height: 80px !important;
    }

    .skyscanner-logo {
        height: 30px !important;
    }

    .booking-logo {
        height: 30px !important;
    }

    .footer-image {
        height: 28px;
    }

    .step-number-badge {
        height: 42px;
        width: 42px;
    }

}
.black{
  color: #1e1e1e;
}
.what_can_be_done_explanation{
  color: rgba(33, 37, 41, 0.75) !important;
  font-size: 1.6rem !important;
  margin-bottom: 1.6rem;
}

.rounded-2 {
    border-radius: 0.5rem !important;
}

row.align-items-stretch {
    display: flex;
    flex-wrap: wrap;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.bg-card {
    background-size: cover;
    background-position: center;
}

@media (min-width: 1200px) {
    .h4, h4 {
        font-size: 2.3rem;
    }
}
.h4, h4 {
    font-size: 2.2rem;
}
h2, h2 {
    font-size: calc(2.2rem + 1.5vw);
}
.justify-content-space-around {
    justify-content: space-around;
}
.text-align-center {
    text-align: center;
}
.clickable:not(:disabled):not(.disabled) {
    cursor: pointer;
}

card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.card-body .flex-grow-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-body h4 {
    min-height: 50px; /* Adjust as needed */
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-body p {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem; /* Adjust as needed */
}
.internal-bady {
  line-height: 1.5;
  
}

</style>
